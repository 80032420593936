<div class="container">
  <h2>Key App</h2>
  <div *ngIf="sent">
    <h3 style="text-align: center; color: orange">{{ key }}</h3>
    <button ngxClipboard [cbContent]="key" (click)="copyed = true">
      <span *ngIf="!copyed">Копировать</span>
      <span *ngIf="copyed">Скопировано</span>
    </button>
  </div>
  <button
    *ngIf="!sent && !loading"
    style="display: inline-block"
    (click)="generateAppKey()"
  >
    Сгенерировать новый ключ
  </button>
  <div style="text-align: center" *ngIf="loading">Loading...</div>
</div>
