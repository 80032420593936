<div class="container">
  <h2>Заявки чекера</h2>
  <div *ngIf="loading" style="text-align: center">Loading...</div>
  <main *ngIf="!loading">
    <table class="table">
      <thead>
        <tr>
          <td>#</td>
          <td style="width: 50px">Дата и время</td>
          <td>ID платежа</td>
          <td>Сумма</td>
          <td>Сообщение</td>
          <td>Файлы</td>
          <td>Статус</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of items"
          [ngClass]="{ new_message: item.trader_view === false }"
        >
          <td style="width: 50px">{{ item["id"] }}</td>
          <td>{{ date(item.created_at) }}</td>
          <td>{{ item.payment_id }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.message_checker }}</td>
          <td>
            <span *ngIf="jsonDecode(item.files).length > 0">Есть</span>
            <span *ngIf="jsonDecode(item.files).length == 0">Нет</span>
          </td>
          <td style="white-space: nowrap">
            <div
              class="chip"
              style="background: orange; color: #000"
              *ngIf="item.closing_time > 0 && item.status == 1"
            >
              {{ leftTime(item.closing_time) }}
            </div>
            {{ getStatus(item.status) }}
          </td>
          <td>
            <a [routerLink]="'/checker-request/' + item.id">Подробнее</a>
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</div>
