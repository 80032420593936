import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CardsApiService, OtherApiService } from "@services/api.service";
import {
  date,
  jsonDecode,
  getStatus,
  convertToUnixTime,
  convertDateTimeZone,
  shortString,
  leftTime,
  leftTime2,
  formatNumberWithSpaces,
} from "@environments/helper";
import { interval } from "rxjs";
import { environment } from "@environments/environment";

@Component({
  selector: "app-deposits",
  templateUrl: "./trader-withdraw.component.html",
  styleUrls: ["../body.css"],
})
export class TraderWithdraw {
  public date = date;
  public leftTime = leftTime;
  public leftTime2 = leftTime2;
  public jsonDecode = jsonDecode;
  public getStatus = getStatus;
  public convertToUnixTime = convertToUnixTime;
  public convertDateTimeZone = convertDateTimeZone;
  JSON = JSON;
  shortString = shortString;
  constructor(
    private otherApiService: OtherApiService,
    private cardsApiService: CardsApiService
  ) {}

  items: any = [];
  loading = true;
  loadingCancel = false;
  loadingMethods = true;
  comment = "";
  showModal = false;
  cancelId = 0;
  cancelI = 0;
  typeWithdraw = null;

  status: any = null;
  selectedMethodIdFilter: any = null;
  commissions: any = [];
  files: any = [];
  methods: any = {};
  typeAction: any = "";
  actionMethod: any = "";
  actionStatus: any = "";
  searchId: any = null;
  textError: any = "";

  formatNumberWithSpaces = formatNumberWithSpaces;

  page = 1;
  lastPage = 0;

  apiUrl = environment.apiUrl;

  async ngOnInit() {
    this.getWithdraw();
    this.getComissions();

    const period = interval(1000);
    period.subscribe((val) => {
      this.updateTimeLeft();
    });
  }

  updateTimeLeft() {
    this.items.map((item: any, index: any) => {
      this.items[index]["taken_at_left"] -= 1;
    });
  }

  beforeAction(i: any, id: any, method: any, status: any) {
    this.textError = "";
    this.showModal = true;
    this.cancelId = id;
    this.cancelI = i;
    this.files = [];
    this.actionMethod = method;
    this.actionStatus = status;
  }

  setStatus(status: any = null) {
    this.status = status;
    this.getWithdraw();
  }

  handleFileInput(e: any) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.files.push(e.target.files[i]);
    }
  }

  getVisiblePages() {
    const visiblePages = [];

    const startPage = Math.max(1, this.page - 2);
    const endPage = Math.min(this.lastPage, this.page + 2);

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  }

  async setPage(page: number) {
    this.page = page;
    await this.getWithdraw();
  }

  async getComissions() {
    this.loadingMethods = true;
    const data = await this.otherApiService.get("get-withdraw-commissions");
    this.loadingMethods = false;
    console.log(data);
    if ((data as any).success) {
      this.commissions = (data as any).result.commissions.data;
      this.commissions.map((item: any) => {
        this.methods[item.key] = item.name;
      });
    }
  }

  async getWithdraw(pageReset?: boolean) {
    if (pageReset) this.page = 1;
    this.loading = true;
    await this.loadWithdraw();
    this.loading = false;
  }

  async loadWithdraw() {
    const data = await this.cardsApiService.getWithdraw(true, {
      page: this.page,
      status: this.status,
      method: this.selectedMethodIdFilter
        ? this.commissions[this.selectedMethodIdFilter]?.key
        : null,
      id: this.searchId == "" ? null : this.searchId,
      desc_id: 'true'
    });
    if ((data as any).success) {
      this.items = (data as any).data;
      this.lastPage = Number((data as any).last_page);
    }
  }

  async withdrawTake(i: any, id: any) {
    this.items[i]["loading"] = true;
    const data = await this.cardsApiService.withdrawTake(id);
    this.items[i]["loading"] = false;

    if ((data as any).message == "taken") {
      this.items[i]["trader_id"] = true;
    }

    if ((data as any).success) {
      this.items[i]["success"] = true;
    }
  }

  async action() {
    if (this.comment == "" && this.actionMethod == "withdraw-cancel") {
      this.textError = "Укажите комментарий";
      return;
    }
    if (this.files.length === 0) {
      this.textError = "Прикрепите файл";
      return;
    }
    this.textError = "";

    this.loadingCancel = true;
    const data = await this.otherApiService.withdrawAction(
      {
        id: this.cancelId,
        comment: this.comment,
        files: this.files,
      },
      this.actionMethod
    );
    this.loadingCancel = false;
    console.log(data);
    if (data) {
      this.showModal = false;
      this.items[this.cancelI].comment_trader = this.comment;
      this.items[this.cancelI].status = this.actionStatus;
      console.log(this.comment);
      console.log(this.actionMethod);
      let files = [];
      for (var i = 0; i < this.files.length; i++) {
        files.push(this.files[i].name);
      }
      this.items[this.cancelI].files = JSON.stringify(files);
      this.files = [];
      this.comment = "";
    }
  }
}
