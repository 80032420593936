import { interval } from "rxjs";
import { Component } from "@angular/core";
import { OtherApiService, PaymentsApiService } from "@services/api.service";
import {
  date,
  jsonDecode,
  getStatus,
  checkBoolText,
  leftTime,
} from "@environments/helper";
import { ActivatedRoute } from "@angular/router";

import { environment } from "@environments/environment";

@Component({
  selector: "app-checker-request",
  templateUrl: "./checkerRequest.component.html",
  styleUrls: ["../body.css"],
})
export class CheckerRequestComponent {
  constructor(
    private paymentsApiService: PaymentsApiService,
    private otherApiService: OtherApiService,
    private route: ActivatedRoute
  ) {}

  date = date;
  jsonDecode = jsonDecode;
  leftTime = leftTime;
  getStatus = getStatus;
  checkBoolText = checkBoolText;
  message = "";
  messageUpClosingTime = "";
  newAmount = "";
  sendConfirmPayment = false;
  successConfirmPayment = false;
  showBlockUpClosingTime = false;
  item: any = [];
  payment: any = null;
  loading = true;
  loadingConfirmPayment = false;
  loadingPayment = true;
  loadingSend = false;
  loadingSendClosingTime = false;
  apiUrl = environment.apiUrl;
  textError: any = "";
  comment = "";
  showModal = false;
  actionStatus: any = "";
  files: any = [];
  Number = Number;

  async ngOnInit() {
    await this.getItem();

    const period = interval(1000);

    period.subscribe((val) => {
      (this.item as any).closing_time_text = "";
    });
  }

  async getItem() {
    const data = await this.otherApiService.getWichParam(
      "get-checker-request",
      { id: this.route.snapshot.params["id"] }
    );
    this.loading = false;
    this.item = (data as any).result;
    this.getPayment();
  }

  async getPayment() {
    console.log(this.item.payment_id);
    this.loadingPayment = true;
    const data = await this.otherApiService.getWichParam("get-payment", {
      id: this.item.payment_id,
    });
    this.loadingPayment = false;
    console.log(data);
    this.payment = (data as any).result;

    this.newAmount =
      Number(this.payment.real_amount) > 0 ? this.payment.real_amount : "";
  }

  async confirmPyament(id: any) {
    console.log(id);
    this.loadingConfirmPayment = true;

    let amountResult =
      Number(this.payment.real_amount) > 0 ? this.payment.real_amount : null;
    const success = await this.paymentsApiService.confirm(id, amountResult);

    this.loadingConfirmPayment = false;
    this.sendConfirmPayment = true;
    if (success) {
      this.successConfirmPayment = true;
    } else {
      this.successConfirmPayment = false;
    }
  }

  async send() {
    if (this.comment == "" && this.actionStatus == "canceled") {
      this.textError = "Укажите комментарий";
      return;
    }

    if (this.files.length === 0 && this.actionStatus == "canceled") {
      this.textError = "Прикрепите файл";
      return;
    }
    this.textError = "";

    this.loadingSend = true;

    const params = {
      id: this.route.snapshot.params["id"],
      comment: this.comment,
      new_amount: this.newAmount,
      files: this.files,
      status: this.actionStatus,
    };
    console.log(params);

    this.loadingSend = true;
    const data = await this.otherApiService.checkerAction(params);
    console.log(data);
    this.loadingSend = false;
    if (data) {
      this.showModal = false;
      this.getItem();
    }
  }

  async sendUpTime() {
    const params = {
      id: this.route.snapshot.params["id"],
      message: this.messageUpClosingTime,
    };
    this.loadingSendClosingTime = true;
    const data = await this.otherApiService.post(
      "checker-up-closing-time",
      params
    );
    this.loadingSendClosingTime = false;
    if ((data as any).success) {
      this.showBlockUpClosingTime = false;
      this.getItem();
    }
  }

  beforeAction(status: any) {
    this.actionStatus = status;
    this.showModal = true;
  }

  handleFileInput(e: any) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.files.push(e.target.files[i]);
    }
  }
}
