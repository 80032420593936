<div class="container">
  <h2>Пул заявок на вывод</h2>
  

<div style="display: flex; justify-content: space-between;">

  <div>
    <button routerLink="/my-withdraw" class="btn btn-green mr-2">
      Мои заявки
    </button>
    <span *ngIf="loadingMethods"> Loading methods... </span>
    <select
      *ngIf="!loadingMethods"
      style="color: #000"
      [(ngModel)]="selectedMethodIdFilter"
      [ngModelOptions]="{ standalone: true }"
      (change)="getWithdraw(true)"
    >
      <option style="color: #000" [value]="null">Все способы</option>
      <option
        style="color: #000"
        *ngFor="let option of commissions; let i = index"
        [value]="i"
      >
        {{ option.name }}
      </option>
    </select>
  
  </div>
  
  <div>
    <div class="pagination">
      <span style="width: auto; padding-left: 5px; padding-right: 5px;" (click)="setPage(page-1)" *ngIf="page != 1">Prev</span>
      <span *ngFor="let option of getVisiblePages(); let i = index" [ngClass]="{ 'active-pagination': option == page }" (click)="setPage(option)" >{{option}}</span>
      <span style="width: auto; padding-left: 5px; padding-right: 5px;" (click)="setPage(page+1)" *ngIf="page != lastPage && lastPage > 0">Next</span>
    </div>
  </div>

</div>

<div *ngIf="loading" style="text-align: center">Loading...</div>
  <main *ngIf="!loading">
    <table class="table">
      <thead>
        <tr>
          <td style="width: 50px">ID</td>
          <td>Способ</td>
          <td>Карта</td>
          <td>Сумма</td>
          <td>Статус</td>
          <td>Создан</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of items; index as i"
          [ngClass]="{ new_message: item.trader_view === false, yallow_tr: item.prioritys }"
        >
          <td style="width: 50px">{{ item.id }}</td>
          <td>
            {{ methods[item.payment_method?.toLowerCase()] }}
          </td>
          <td>
            <div style="white-space: nowrap">
              {{ item.payee_detail }}
            </div>
            <div>{{ item.bank }}</div>
          </td>
          <td>
            <div style="white-space: nowrap;">{{ formatNumberWithSpaces(item.amount) }}</div>
            <br />
            <span
              style="
                background: green;
                padding: 3px;
                border-radius: 5px;
                font-size: 12px;
              "
              >{{ ((item.commission - 1) * 100).toFixed(1) }}%</span
            >
          </td>
          <td>{{ item.status }}</td>
          <td>{{ convertDateTimeZone(item.created_at) }}</td>
          <td>
            <div *ngIf="item.trader_id" style="color: red">Закрыта</div>
            <div *ngIf="!item.trader_id">
              <span *ngIf="item.loading">loading</span>
              <div *ngIf="item.success" style="color: green">Успешно</div>
              <img
                *ngIf="!item.loading && !item.success"
                (click)="withdrawTake(i, item.id)"
                src="/assets/svg/download.svg"
                height="40"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    
  </main>
  
</div>
