<div class="container">
  <h2>История операций</h2>
  <div *ngIf="loading" style="text-align: center">Loading...</div>
  <main *ngIf="!loading">
    <table class="table">
      <thead>
        <tr>
          <td style="width: 50px">#</td>
          <td>Дата и время</td>
          <td>Сумма USDT</td>
          <td>Курс</td>
          <td>Процент</td>
          <td>Сумма</td>
          <td>Баланс</td>
          <td>
            Тип
            <select
              style="color: #000"
              [(ngModel)]="typeDeposit"
              [ngModelOptions]="{ standalone: true }"
              (change)="loadDeposits()"
            >
              <option style="color: #000" value="">Все</option>
              <option style="color: #000" value="plus">Зачисления</option>
              <option style="color: #000" value="plus_from_withdraw">
                Зачисления c вывода
              </option>
              <option style="color: #000" value="minus">
                Списания операций
              </option>
              <option style="color: #000" value="minus_admin">
                Списания админа
              </option>

              <option style="color: #000" value="plus_admin">
                Зачисления админа
              </option>

              <option style="color: #000" value="minus_trader">
                Перевод трейдеру(списание)
              </option>

              <option style="color: #000" value="plus_trader">
                Перевод трейдеру(пополнение)
              </option>
            </select>
          </td>
          <td>Payment ID</td>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of items"
          [ngClass]="{ new_message: item.trader_view === false }"
        >
          <td style="width: 50px">{{ item["id"] }}</td>
          <td>{{ date(item.created_at) }}</td>
          <td>{{ item.amount_usdt }}</td>
          <td>{{ item.rate }}</td>
          <td>{{ item.percent }}<span *ngIf="item.percent">%</span></td>
          <td>
            {{ item.amount_deposit }}
            <div *ngIf="item.referral_id > 0">
              <span class="chip">Реферал {{ item.referral_username }}</span>
            </div>
          </td>
          <td>{{ item.balance_trader }}</td>
          <td>{{ item.type_deposit }}</td>
          <td>{{ item.payment_id }}</td>
        </tr>
      </tbody>
    </table>
  </main>
</div>
