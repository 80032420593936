import { Component } from "@angular/core";
import { CardsApiService } from "@services/api.service";
import { environment } from "@environments/environment";
import { getCookie } from "@environments/helper";

@Component({
  selector: "app-keys",
  templateUrl: "./tgBot.component.html",
  styleUrls: ["../body.css"],
})
export class TgBot {
  
  loading = true;
  data: any = null;
  Number = Number;

  
  link = "https://t.me/" + environment.tgBotUsername + "?start=" +  JSON.parse(getCookie("json_token") || "{}")["token"];

  constructor(private cardsApiService: CardsApiService) {}


  ngOnInit() {
    this.connect();
  }

  async connect() {
    this.loading = true;
    const data = await this.cardsApiService.telegramGet();
    console.log((data as any).data[0]);
    this.loading = false;
    this.data = (data as any).data[0]
  }

  async remove() {
    this.loading = true;
    const data = await this.cardsApiService.telegramDelete();
    this.loading = false;
    this.connect();
  }
}
