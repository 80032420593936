<div class="container">
  <h2>
    <span style="padding-right: 20px">Мои заявки на вывод</span>
    <button
      routerLink="/withdraw"
      style="margin-right: 20px"
      class="btn btn-sm btn-primary"
    >
      Пул заявок
    </button>
  </h2>

  

  <div class="modal" *ngIf="showModal">
    <div
      class="modal-block"
      [class]="[actionStatus == 'confirmed' ? 'bg-green' : 'bg-red']"
    >
      <div class="title" *ngIf="actionStatus == 'confirmed'">
        Подтвердить заявку
      </div>
      <div class="title" *ngIf="actionStatus == 'canceled'">
        Отменить заявку
      </div>
      <textarea
        [(ngModel)]="comment"
        rows="5"
        style="background: none; border-radius: 10px"
      ></textarea>
      <div style="margin-bottom: 30px">
        <input
          type="file"
          id="file"
          multiple
          (change)="handleFileInput($event)"
        />
      </div>
      <button *ngIf="loadingCancel" class="btn-error" style="width: 100%">
        Loading...
      </button>
      <div
        *ngIf="textError != ''"
        style="color: red; text-align: center; padding: 20px"
      >
        {{ textError }}
      </div>
      <button
        *ngIf="!loadingCancel"
        (click)="action()"
        class="btn"
        [class]="[actionStatus == 'confirmed' ? 'btn-success' : 'btn-error']"
        style="width: 100%"
      >
        <span *ngIf="actionStatus == 'confirmed'">Подтвердить заявку</span>
        <span *ngIf="actionStatus == 'canceled'">Отменить заявку</span>
      </button>
      <button
        (click)="showModal = false; files = []"
        class="btn btn-primary"
        style="width: 100%"
      >
        Закрыть окно
      </button>
    </div>
  </div>

  

  <div style="margin-top: 10px; margin-bottom: 10px">
    <button class="btn btn-primary mr-2 btn-sm" (click)="setStatus()">
      Все
    </button>
    <button class="btn btn-warning mr-2 btn-sm" (click)="setStatus('pending')">
      Pending
    </button>
    <button
      class="btn btn-success mr-2 btn-sm"
      (click)="setStatus('confirmed')"
    >
      Confirmed
    </button>
    <button class="btn btn-error mr-2 btn-sm" (click)="setStatus('canceled')">
      Canceled
    </button>

    <span *ngIf="loadingMethods"> Loading methods... </span>
    <select
      *ngIf="!loadingMethods"
      style="color: #000; margin-right: 10px"
      [(ngModel)]="selectedMethodIdFilter"
      [ngModelOptions]="{ standalone: true }"
      (change)="getWithdraw(true)"
    >
      <option style="color: #000" [value]="null">Все способы</option>
      <option
        style="color: #000"
        *ngFor="let option of commissions; let i = index"
        [value]="i"
      >
        {{ option.name }}
      </option>
    </select>
    <input
      [(ngModel)]="searchId"
      type="text"
      placeholder="Поиск По ID"
      style="
        background: #fff;
        width: 110px;
        font-size: 16px;
        border-radius: 5px;
        color: #000;
        margin-right: 5px;
      "
    />
    <button class="btn btn-primary mr-2 btn-sm" (click)="getWithdraw(true)">
      Найти
    </button>
  </div>

  <div class="pagination">
    <span style="width: auto; padding-left: 5px; padding-right: 5px;" (click)="setPage(page-1)" *ngIf="page != 1">Prev</span>
    <span *ngFor="let option of getVisiblePages(); let i = index" [ngClass]="{ 'active-pagination': option == page }" (click)="setPage(option)" >{{option}}</span>
    <span style="width: auto; padding-left: 5px; padding-right: 5px;" (click)="setPage(page+1)" *ngIf="page != lastPage && lastPage > 0">Next</span>
  </div>

  <div *ngIf="loading" style="text-align: center">Loading...</div>
  <main *ngIf="!loading">
    <table class="table">
      <thead>
        <tr>
          <td style="width: 50px">ID</td>
          <td>Способ</td>
          <td>Карта</td>
          <td>Сумма</td>
          <td>Статус</td>
          <td>Создан</td>
          <td>Файлы</td>
          <td>Действия</td>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of items; index as i"
          [ngClass]="{ new_message: item.trader_view === false, yallow_tr: item.prioritys }"
        >
          <td style="width: 50px">{{ item.id }}</td>
          <td>
            {{ methods[item.payment_method?.toLowerCase()] }}
          </td>
          <td>
            <div style="white-space: nowrap">
              {{ item.payee_detail }}
              <img
                ngxClipboard
                [cbContent]="item.payee_detail"
                width="20"
                src="/assets/svg/copy.svg"
              />
            </div>
            <br />
            {{ item.bank }}
          </td>
          <td>
            <div style="white-space: nowrap;">{{ formatNumberWithSpaces(item.amount) }}</div>
            <br />
            <span
              style="
                background: green;
                padding: 3px;
                border-radius: 5px;
                font-size: 12px;
              "
              >{{ ((item.commission - 1) * 100).toFixed(1) }}%</span
            >
          </td>
          <td>
            {{ item.status }}
            <div style="text-align: center; margin-top: 5px">
              <span
                *ngIf="item.status == 'pending'"
                class="btn btn-green btn-sm"
              >
                {{ leftTime2(item.taken_at_left) }}
              </span>
            </div>

            <div style="text-align: center; margin-top: 5px">
              <span *ngIf="item.auto_close" class="btn btn-error btn-sm">
                Auto close
              </span>
            </div>
          </td>
          <td>{{ convertDateTimeZone(item.created_at) }}</td>
          <td>
            <div
              style="margin-top: 10px"
              *ngFor="let file of JSON.parse(item.files); index as i2"
            >
              <a target="_blank" [href]="apiUrl + '/files/' + file">
                {{ shortString(file) }}
              </a>
            </div>
          </td>
          <td>
            <div
              *ngIf="item.status == 'pending' && !item.loading"
              style="text-wrap: nowrap"
            >
              <img
                (click)="
                  beforeAction(i, item.id, 'withdraw-confirm', 'confirmed')
                "
                src="/assets/svg/check-read.svg"
                style="margin-right: 5px"
                width="30px"
              />
              <img
                (click)="
                  beforeAction(i, item.id, 'withdraw-cancel', 'canceled')
                "
                src="/assets/svg/close.svg"
                width="30px"
              />
            </div>
            <span *ngIf="item.loading">loading...</span>
            <div *ngIf="item.comment_trader">
              {{ item.comment_trader }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</div>
