import { Component, Input, OnInit } from "@angular/core";

import { Router } from "@angular/router";

import { OtherApiService } from "@services/api.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["../body.css"],
})
export class Login implements OnInit {
  constructor(
    private cookieService: CookieService,
    private router: Router,
    private otherApiService: OtherApiService
  ) {}

  login = "";
  password = "";
  loading = false;
  error = "";

  ngOnInit() {}

  async auth() {
    this.error = "";
    this.loading = true;
    console.log(this.login);
    console.log(this.password);

    const data = await this.otherApiService.post("login", {
      login: this.login,
      password: this.password,
    });
    this.loading = false;
    if ((data as any).success) {
      const cookie = JSON.stringify({
        id: (data as any).result.id,
        username: (data as any).result.username,
        token: (data as any).result.auth_token_hash,
      });
      document.cookie = `json_token=${cookie}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
      location.href = "/cards";
    } else {
      this.error = (data as any).result;
    }
    console.log(data);
  }
}
