import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { TabulatorFull as Tabulator } from "tabulator-tables";
import { CookieService } from "ngx-cookie-service";
import { FormControl } from "@angular/forms";

import { environment } from "@environments/environment";

import { PaymentsApiService } from "@services/api.service";
import { TabulatorAdditionalService } from "@services/tabulator-additional.service";
import { DateTime } from "luxon"; // зависимость табулятора
import { getCookie } from "@environments/helper";

window.DateTime = DateTime;

@Component({
  selector: "app-payments-list",
  templateUrl: "./payments-list.component.html",
  styleUrls: ["./payments-list.component.css"],
})
export class PaymentsListComponent {
  json_token = "";
  auth_token = "";
  username = "";
  holderId = "";

  table: any;

  realAmountInput: any = new FormControl();

  constructor(
    private cookieService: CookieService,
    private paymentsApiService: PaymentsApiService,
    private tabulatorAdditional: TabulatorAdditionalService
  ) {
    this.json_token = this.cookieService.get("json_token");

    this.auth_token = JSON.parse(this.json_token || "{}")["token"];
    this.username = JSON.parse(this.json_token || "{}")["username"];
    this.holderId = JSON.parse(this.json_token || "{}")["id"];
  }

  ngOnInit() {
    this.drawTable();
  }

  async payment() {
    this.loading = true;
    const success = await this.paymentsApiService.confirm(this.id, this.amount);
    this.loading = false;

    if (success) {
      this.cell.setValue("true");
    }

    this.showModal = false;
  }

  cancel() {
    this.id = 0;
    this.cell = null;
    this.amount = null;
    this.showModal = false;
  }

  id: any = 0;
  cell: any = null;
  amount: any = null;
  showModal = false;
  loading = false;

  rowContextMenu = (e: any, component: any) => {
    var optionConfirm = {
      label: "Подтвердить платёж",
      disabled: false,
      action: async (e: any, row: any) => {
        const realAmount = this.realAmountInput.value;

        this.realAmountInput.clear;

        const id = row.getCell("id").getValue();

        const success = await this.paymentsApiService.confirm(id, realAmount);

        if (success) {
          row.getCell("status").setValue("true");
          if (realAmount) row.getCell("real_amount").setValue(realAmount);
        }
      },
    };

    var optionRecallback = {
      label: "Переотправить запрос",
      disabled: false,
      action: async (e: any, row: any) => {
        const id = row.getCell("id").getValue();
        const realAmount = row.getCell("real_amount").getValue();

        const success = await this.paymentsApiService.resendCallback(id);
      },
    };

    var menu = [];

    const status = component.getCell("status").getValue();

    if (status == "true") optionConfirm.disabled = true;
    else optionRecallback.disabled = true;

    menu.push(optionConfirm);
    menu.push(optionRecallback);

    return menu;
  };

  private drawTable(): void {
    var table = document.createElement("div");

    var auth = "role=trader&holder_id=" + this.holderId;

    this.table = new Tabulator(table, {
      persistence: {
        filter: true,
        columns: true,
      },
      persistenceID: "payments-list",
      filterMode: "remote",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 300,
      paginationSizeSelector: [5, 10, 15, 20, 50, 100, 5000, 100000, true],
      ajaxURL: environment.apiUrl + "/payments/get?" + auth,
      ajaxConfig: {
        headers: {
          Authorization: this.auth_token,
        },
      },
      rowContextMenu: this.rowContextMenu,
      layout: "fitData",
      height: "100%",
      columns: [
        {
          title: "ID",
          field: "id",
          headerFilter: "input",
          headerFilterFunc: "=",
          headerSort: false,
        },
        {
          title: "ID пользователя",
          field: "user_id",
          headerFilter: "input",
          headerSort: false,
        },
        {
          title: "Сумма",
          field: "amount",
          headerFilter: "number",
          bottomCalc: "sum",
          bottomCalcParams: {
            precision: 1,
          },
          headerSort: false,
        },
        {
          title: "Изменённая сумма",
          field: "real_amount",
          headerFilter: "number",
          bottomCalc: "sum",
          bottomCalcParams: {
            precision: 1,
          },
          headerSort: false,
        },
        {
          title: "Номер карты",
          field: "card_number",
          headerFilter: "input",
          headerSort: false,
        },
        {
          title: "ФИО",
          field: "card_name",
          headerFilter: "input",
          headerSort: false,
        },
        {
          formatter: (cell) => {
            let color = "#fff";
            const data: any = cell.getData();
            if (data.status == "canceled") color = "red";
            if (data.status == "true") color = "green";
            if (data.status == "false") color = "orange";

            const check =
              data.status != "true"
                ? "<span ><img src='/assets/svg/check2.svg' class='check_pay' width='20px'></span>"
                : "";

            return `<span style="color: ${color};"> ${data.status} ${check}</span>`;
          },
          cellClick: async (e, cell: any) => {
            const data: any = cell.getData();

            if (data.status == "true") return;

            this.amount = this.realAmountInput.value;
            this.id = data.id;
            this.cell = cell;

            this.showModal = true;
          },
          title: "Статус",
          field: "status",
          headerFilter: "list",
          headerFilterParams: {
            values: {
              true: "true",
              false: "false",
              review: "review",
              canceled: "canceled",
              "": "any",
            },
            clearable: true,
          },
          headerSort: false,
        },
        {
          title: "Дата создания",
          field: "creation_time",
          formatter: "datetime",
          formatterParams: {
            inputFormat: "yyyy-MM-dd HH:mm:ss.u",
            outputFormat: "yyyy-MM-dd HH:mm:ss",
            invalidPlaceholder: "(invalid date)",
          },
          headerFilter: "input", // временно
          // headerFilter:this.tabulatorAdditional.minMaxStringFilterEditor,
          // headerFilterFunc:this.tabulatorAdditional.minMaxDateFilterFunction,
          headerSort: false,
        },
        {
          title: "Валюта",
          field: "currency",
          headerFilter: "input",
          headerSort: false,
        },
      ],
    });

    document.getElementById("main-table")?.appendChild(table);
  }
}
