<div class="container">
  <h2>История SMS</h2>
  <div *ngIf="loading" style="text-align: center">Loading...</div>
  <main *ngIf="!loading">
    <div style="height: 700px" id="main-table">
      <table class="table">
        <thead>
          <tr>
            <td style="width: 50px">ID</td>
            <td style="width: 50px">Отправитель</td>
            <td>Описание</td>
            <td>Дата</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items">
            <td style="width: 50px">{{ item["id"] }}</td>
            <td style="width: 50px">{{ item.sender_phone_number }}</td>
            <td>{{ item.text }}</td>
            <td>{{ item.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</div>
