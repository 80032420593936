import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { OtherApiService } from "@services/api.service";
import { date, jsonDecode, getStatus } from "@environments/helper";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-sms",
  templateUrl: "./sms.component.html",
  styleUrls: ["../body.css"],
})
export class Sms {
  public date = date;
  public jsonDecode = jsonDecode;
  public getStatus = getStatus;

  constructor(
    private otherApiService: OtherApiService,
    private route: ActivatedRoute
  ) {}

  items: any = [];
  loading = true;
  phone_number = "";

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.phone_number = params["phone_number"];
      this.loadSms();
    });
  }

  async loadSms() {
    const data = await this.otherApiService.get("get-sms", {
      phone_number: this.phone_number,
    });
    console.log(data);
    this.loading = false;
    this.items = (data as any).result;
  }
}
