import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { OtherApiService } from "@services/api.service";
import { date } from "@environments/helper";
import { interval } from "rxjs";

@Component({
  selector: "app-notifs",
  templateUrl: "./ban.component.html",
  styleUrls: ["../body.css"],
})
export class Ban {
  public date = date;
  ban_time = 0;
  loading = true;
  constructor(private otherApiService: OtherApiService) {}

  async ngOnInit() {
    this.loading = true;
    await this.getStatusBan();
    this.loading = false;

    const period = interval(10000);
    period.subscribe((val) => {
      this.getStatusBan();
    });
  }

  async getStatusBan() {
    const data = await this.otherApiService.getApi("get-status-ban");
    console.log(data);
    if ((data as any).success) {
      this.ban_time = (data as any).result.ban_time;
    }
  }
}
