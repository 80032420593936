import { Component } from '@angular/core';

import { TabulatorFull as Tabulator } from 'tabulator-tables';

@Component({
  selector: 'app-wallets-table',
  templateUrl: './wallets-table.component.html',
  styleUrls: ['./wallets-table.component.css']
})

export class WalletsTableComponent {
  allItems: any = [
      {
        'name':'TRC20',
        'key':'TLb2kNnt23XaLyj14G8xzE9TahzzUJFzCN',
        'description':'Для отправки страхового депозита'
      }
    ];
  constructor() {};

  ngOnInit(){
    this.drawTable();
  }

  private drawTable(): void {
    var table = document.createElement('div');

    new Tabulator(table, {
      data: this.allItems,
      columns: [
        {
          title: "Сеть",
          field:"name"
        },
        {
          title: "Публичный ключ",
          field:"key"
        },
        {
          title: "Описание",
          field:"description"
        }
      ],
      layout: 'fitData',
      height: "100%"
    });
    document.getElementById('main-table')?.appendChild(table);
  }

}

interface wallet {
  name: string;
  key: string;
  description: string;
}