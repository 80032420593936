import { Component } from "@angular/core";

import { TabulatorFull as Tabulator } from "tabulator-tables";
import { FormGroup, FormControl, AbstractControl } from "@angular/forms";
import { Router } from "@angular/router";

import { CookieService } from "ngx-cookie-service";
import { environment } from "@environments/environment";
import { CardsApiService } from "@services/api.service";

import { getCookie, banks } from "@environments/helper";

@Component({
  selector: "app-cards-table",
  templateUrl: "./cards-table.component.html",
  styleUrls: ["./cards-table.component.css"],
})
export class CardsTableComponent {
  json_token = "";
  username = "";
  holderId = "";

  cardForm: any = new FormGroup({
    number: new FormControl(),
    name: new FormControl(),
    phone: new FormControl(),
    bank: new FormControl("sberbank"),
    is_using_account_number: new FormControl(false),
    account_number: new FormControl(),
  });

  table: any;
  banks: any = banks;
  error: any = "";

  constructor(
    private cookieService: CookieService,
    private cardsApiService: CardsApiService,
    private router: Router
  ) {
    this.json_token = this.cookieService.get("json_token");
    this.username = JSON.parse(this.json_token || "{}")["username"];
    this.holderId = JSON.parse(this.json_token || "{}")["id"];
  }

  ngOnInit() {
    this.drawTable();
  }

  rowContextMenu = [
    {
      label: "Активировать",
      action: async (e: any, row: any) => {
        const number = row.getCell("number").getValue();

        const success = await this.cardsApiService.activate(number);

        if (success) {
          row.getCell("active").setValue(true);
        }
      },
    },
    {
      label: "Деактивировать",
      action: async (e: any, row: any) => {
        const number = row.getCell("number").getValue();

        const success = await this.cardsApiService.deactivate(number);

        if (success) {
          row.getCell("active").setValue(false);
        }
      },
    },
    {
      label: "СБП",
      menu: [
        {
          label: "Активировать",
          action: async (e: any, row: any) => {
            const number = row.getCell("number").getValue();

            const success = await this.cardsApiService.activateSbp(number);

            if (success) {
              row.getCell("is_sbp").setValue(true);
            }
          },
        },
        {
          label: "Деактивировать",
          action: async (e: any, row: any) => {
            const number = row.getCell("number").getValue();

            const success = await this.cardsApiService.deactivateSbp(number);

            if (success) {
              row.getCell("is_sbp").setValue(false);
            }
          },
        },
      ],
    },
    {
      label: "Список СМС",
      action: async (e: any, row: any) => {
        const cardNumber = row.getCell("number").getValue();
        const phoneNumber = row.getCell("phone_number").getValue();

        this.router.navigate(["/sms/" + phoneNumber + "/" + cardNumber]);
      },
    },
    {
      label: "Арихивировать",
      action: async (e: any, row: any) => {
        console.log(row.getData().id);
        const success = await this.cardsApiService.addArchive(row.getData().id);
        if (success) {
          row.delete();
        }
      },
    },
  ];

  headerMenu = [
    {
      label: "Активировать всё",
      action: async (e: any, column: any) => {
        const success = await this.cardsApiService.activateAll();

        if (success) {
          const cells = column.getCells();

          cells.forEach((cell: any) => cell.setValue(true));
        }
      },
    },
    {
      label: "Деактивировать всё",
      action: async (e: any, column: any) => {
        const success = await this.cardsApiService.deactivateAll();

        if (success) {
          const cells = column.getCells();

          cells.forEach((cell: any) => cell.setValue(false));
        }
      },
    },
  ];

  private drawTable(): void {
    var div = document.createElement("div");

    this.table = new Tabulator(div, {
      persistence: {
        filter: true,
        sort: true,
        columns: true,
      },
      persistenceID: "cards",
      filterMode: "remote",
      sortMode: "remote",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 300,
      paginationSizeSelector: [5, 10, 15, 20, 50, 100, true],
      ajaxURL:
        environment.apiUrl +
        "/cards/get?role=trader&archive=false&holder_id=" +
        this.holderId,
      ajaxConfig: {
        headers: {
          Authorization: JSON.parse(getCookie("json_token") || "{}")["token"],
        },
      },
      rowContextMenu: this.rowContextMenu,
      layout: "fitData",
      height: "100%",

      columns: [
        {
          title: "Номер карты",
          field: "number",
          headerFilter: "input",
          width: 250,
        },
        {
          title: "Номер счета",
          field: "account_number",
          headerFilter: "input",
        },
        {
          title: "Баланс",
          field: "balance",
          headerFilter: "number",
        },
        {
          title: "ФИО",
          field: "name",
          headerFilter: "input",
        },
        {
          title: "Телефон",
          field: "phone_number",
          headerFilter: "input",
        },
        {
          title: "Банк",
          field: "bank",
          headerFilter: "input",
          formatter: (cell) => {
            const data: any = cell.getData();
            const bankName = this.banks.filter(
              (bank: any) => bank.value === data.bank
            );
            if (bankName.length == 0) return "unknown";
            return bankName[0].name;
          },
        },
        {
          title: "Статус",
          field: "active",
          formatter: "tickCross",
          headerFilter: "list",
          headerFilterParams: {
            values: {
              true: "true",
              false: "false",
              "": "any",
            },
            clearable: true,
          },
          hozAlign: "right",
          headerMenu: this.headerMenu,
          cellClick: async (e, row) => {
            const data: any = row.getData();
            let success = false;

            if (!data.active) {
              success = await this.cardsApiService.activate(data.number);
            } else {
              success = await this.cardsApiService.deactivate(data.number);
            }

            if (success) {
              row.setValue(!data.active);
            }
          },
        },
        {
          title: "СБП",
          field: "is_sbp",
          //  headerFilter: "list",
          width: "6.5em",
          headerFilterParams: {
            values: {
              true: "true",
              false: "false",
              "": "any",
            },
            clearable: true,
          },
          cellClick: async (e, row) => {
            const data: any = row.getData();
            let success = false;

            if (!data.is_sbp) {
              success = await this.cardsApiService.activateSbp(data.number);
            } else {
              success = await this.cardsApiService.deactivateSbp(data.number);
            }

            if (success) {
              row.setValue(!data.is_sbp);
            }
          },
          formatter: function (cell) {
            const value = cell.getValue();
            if (value == true) {
              return '<img style="height: 1em; width: auto;" src="/assets/png/sbp-colorful.png"/>';
            } else {
              return '<img style="height: 1em; width: auto;" src="/assets/png/sbp-dark.png"/>';
            }
          },
          hozAlign: "right",
        },
        {
          title: "Архив",
          field: "archive",
          width: "6.5em",
          headerFilterParams: {
            values: {
              true: "true",
              false: "false",
              "": "any",
            },
            clearable: true,
          },
          cellClick: async (e, row: any) => {
            const data: any = row.getData();
            let success = false;

            if (!data.archive)
              success = await this.cardsApiService.addArchive(data.id);
            else success = await this.cardsApiService.removeArchive(data.id);

            console.log(!data.archive);
            if (success) {
              row.setValue(!data.archive);
            }
          },
          formatter: function (cell) {
            const value = cell.getValue();
            if (value) {
              return '<img style="height: 1em; width: auto;" src="/assets/svg/confirm.svg"/>';
            } else {
              return '<img style="height: 1em; width: auto;" src="/assets/svg/trash.svg"/>';
            }
          },
          hozAlign: "right",
        },
        {
          width: 150,
          title: "Edit",
          field: "username",
          headerFilter: "input",
          formatter: (cell) => {
            const data: any = cell.getData();

            return `
            <a href="/card/${data.id}"><img src="/assets/svg/settings.svg" style="width: 20px; margin-right: 5px;"></a>
            <a href="/sms/${data.phone_number}"><img src="/assets/svg/sms.svg" style="width: 20px; "></a>
            <a href="/sms/${data.phone_number}/${data.number}"><img src="/assets/svg/sms-list.svg"  style="width: 20px; "></a>
            `;
          },
        },
      ],
    });

    document.getElementById("main-table")?.appendChild(div);
  }

  async createCard() {
    const card = this.cardForm.value;
    this.error = "";

    if (
      card.is_using_account_number &&
      (card.account_number == "" || card.account_number == null)
    ) {
      this.error = "Укажите номер счета";
      return;
    }

    const success = await this.cardsApiService.create(card);

    if (success) {
      this.table.addRow(card);
      this.cardForm.reset();
    }
  }

  public openModal() {
    document.getElementById("modal-create")!.style.display = "block";
  }

  public onCloseModal(): void {
    document.getElementById("modal-create")!.style.display = "none";
  }
}
