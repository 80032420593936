<div class="container">
  <h2>Заявка чекера #{{ item.id }}</h2>
  <div *ngIf="loadingPayment" style="text-align: center">
    Загрузка платежа...
  </div>
  <main>
    <div *ngIf="!loadingPayment" style="overflow: auto">
      <table style="margin-top: 10px; margin-bottom: 20px; width: 100%">
        <thead>
          <tr>
            <td>Создан</td>
            <td>ID платежа</td>
            <td>Статус</td>
            <td>User</td>
            <td>Сумма</td>
            <td>Измененная сумма</td>
            <td>ФИО карты</td>
            <td>Номер карты</td>
            <td>Валюта</td>
            <td>Комм.</td>
          </tr>
        </thead>
        <tbody>
          <tr class="tr">
            <td>{{ payment.creation_time }}</td>
            <td>{{ payment.in_payment_id }}</td>
            <td>{{ payment.status }}</td>
            <td>{{ payment.user_id }}</td>
            <td>{{ payment.amount }}</td>
            <td>{{ payment.real_amount }}</td>
            <td>{{ payment.card_name }}</td>
            <td>{{ payment.card_number }}</td>
            <td>{{ payment.currency }}</td>
            <td>{{ payment.comment }}</td>
          </tr>
        </tbody>
      </table>
      <!-- <div *ngIf="!loadingConfirmPayment && !sendConfirmPayment">
    <button
      (click)="confirmPyament(payment.in_payment_id)"
      *ngIf="payment.status != 'true'"
    >
      Подтвердить платеж #{{ payment.in_payment_id }}
    </button>
  </div> -->
      <div
        *ngIf="loadingConfirmPayment"
        style="text-align: center; margin-top: 10px"
      >
        Подтверждение платежа...
      </div>
      <div
        *ngIf="sendConfirmPayment"
        style="text-align: center; margin-top: 10px; color: green"
      >
        Платеж подтвержден
      </div>
    </div>

    <div *ngIf="loading" style="text-align: center">Loading...</div>

    <table *ngIf="!loading" class="table table-request">
      <table class="table">
        <tbody>
          <tr>
            <td>Дата и время заявки</td>
            <td>{{ date(item.created_at) }}</td>
          </tr>
          <tr>
            <td>ID платежа</td>
            <td>{{ item.payment_id }}</td>
          </tr>
          <tr>
            <td>Сумма</td>
            <td>{{ item.amount }}</td>
          </tr>
          <tr>
            <td>Cообщение чекер</td>
            <td>{{ item.message_checker }}</td>
          </tr>
          <tr>
            <td>Файлы чекер</td>
            <td>
              <div *ngFor="let file of jsonDecode(item.files)">
                <a target="_blank" [href]="apiUrl + '/files/' + file">{{
                  file
                }}</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Ваше сообщение</td>
            <td>{{ item.message_trader }}</td>
          </tr>

          <tr>
            <td>Ваши файлы</td>
            <td>
              <div *ngFor="let file of jsonDecode(item.files_trader)">
                <a target="_blank" [href]="apiUrl + '/files/' + file">{{
                  file
                }}</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Статус</td>
            <td>
              <div style="display: none">
                <div
                  style="color: green"
                  *ngIf="item.closing_time > 0 && item.status == 1"
                >
                  {{ leftTime(item.closing_time) }}
                  <span
                    *ngIf="!item.status_up_closing_time"
                    class="btn"
                    (click)="showBlockUpClosingTime = !showBlockUpClosingTime"
                    >Запрос на увелечение</span
                  >
                  <span
                    style="color: orange; font-size: 14px"
                    *ngIf="item.status_up_closing_time"
                    >(Запрос на увелечние лимита отправлен)</span
                  >
                </div>

                <div
                  class="checker-block-answer"
                  *ngIf="item.status == 1 && showBlockUpClosingTime"
                >
                  <input
                    type="text"
                    [(ngModel)]="messageUpClosingTime"
                    placeholder="Укажите причину и кол-во часов/минут для увеличения"
                  />
                  <button
                    *ngIf="!loadingSendClosingTime"
                    (click)="sendUpTime()"
                  >
                    Отправить
                  </button>
                  <button *ngIf="loadingSendClosingTime">Loading...</button>
                </div>
              </div>
              {{ getStatus(item.status) }}
              <span *ngIf="item.status == 1" style="text-wrap: nowrap">
                <img
                  (click)="beforeAction('confirmed')"
                  src="/assets/svg/check-read.svg"
                  style="margin-right: 5px"
                  width="30px"
                  height="30px"
                />
                <img
                  (click)="beforeAction('canceled')"
                  src="/assets/svg/close.svg"
                  width="30px"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </table>

    <!-- <div class="checker-block-answer" *ngIf="item.status == 1">
      <input
        type="text"
        [(ngModel)]="message"
        placeholder="Ваше сообщение для чекера"
      />
      <input
        type="number"
        [(ngModel)]="newAmount"
        *ngIf="!checkBoolText(payment?.status) && !loadingPayment"
        placeholder="Новая сумма"
      />
      <button *ngIf="!loadingSend" (click)="send()">Отправить</button>
      <button *ngIf="loadingSend">Loading...</button>
    </div>

    <div class="checker-block-answer" *ngIf="item.status == 0">
      <h1>Ваш ответ по заявке</h1>
      <div class="text-trader">{{ item.message_trader }}</div>
      <div *ngIf="item.new_amount > 0 && item.new_amount != item.amount">
        Новая сумма <br />
        <strong>{{ item.new_amount }}</strong>
      </div>
    </div> -->
  </main>

  <div class="modal" *ngIf="showModal">
    <div
      class="modal-block"
      [class]="[actionStatus == 'confirmed' ? 'bg-green' : 'bg-red']"
    >
      <div class="title" *ngIf="actionStatus == 'confirmed'">
        Подтвердить заявку
      </div>
      <div class="title" *ngIf="actionStatus == 'canceled'">
        Отменить заявку
      </div>
      <textarea
        *ngIf="actionStatus == 'canceled'"
        [(ngModel)]="comment"
        rows="5"
        style="background: none; border-radius: 10px"
      ></textarea>
      <div style="color: #000; font-size: 14px; margin-bottom: 5px">
        Новая сумма
      </div>
      <input
        style="
          background: #fff;
          color: #000;
          margin-bottom: 10px;
          border-radius: 5px;
        "
        type="number"
        [(ngModel)]="newAmount"
        placeholder="Новая сумма"
        *ngIf="actionStatus == 'confirmed'"
      />
      <div style="margin-bottom: 30px" *ngIf="actionStatus == 'canceled'">
        <input
          type="file"
          id="file"
          multiple
          (change)="handleFileInput($event)"
        />
      </div>
      <button *ngIf="loadingSend" class="btn-error" style="width: 100%">
        Loading...
      </button>
      <div
        *ngIf="textError != ''"
        style="color: red; text-align: center; padding: 20px"
      >
        {{ textError }}
      </div>

      <div
        *ngIf="Number(newAmount) > Number(payment.amount) + 10000"
        style="padding: 10px; color: red; text-align: center"
      >
        Измененная сумма больше на 10 000 RUB чем в заявке. <br />
        Вы уверены?
      </div>
      <div>
        <button
          *ngIf="!loadingSend"
          (click)="send()"
          class="btn"
          [class]="[actionStatus == 'confirmed' ? 'btn-success' : 'btn-error']"
          style="width: 100%"
        >
          <span *ngIf="actionStatus == 'confirmed'">Подтвердить заявку</span>
          <span *ngIf="actionStatus == 'canceled'">Отменить заявку</span>
        </button>
      </div>
      <button
        (click)="showModal = false; files = []"
        class="btn btn-primary"
        style="width: 100%"
      >
        Закрыть окно
      </button>
    </div>
  </div>
</div>
