import { Component } from "@angular/core";
import { OtherApiService } from "@services/api.service";

@Component({
  selector: "app-keys",
  templateUrl: "./keys.component.html",
  styleUrls: ["../body.css"],
})
export class Keys {
  sent = false;
  key = "";
  loading = false;
  copyed = false;

  constructor(private otherApiService: OtherApiService) {}

  async generateAppKey() {
    this.loading = true;
    const data = await this.otherApiService.get("generate-key");
    console.log(data);
    this.loading = false;
    this.sent = true;
    this.key = (data as any).result.key;
  }
}
