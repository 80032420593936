import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { OtherApiService } from "@services/api.service";
import { date, jsonDecode, getStatus } from "@environments/helper";

@Component({
  selector: "app-deposits",
  templateUrl: "./deposits.component.html",
  styleUrls: ["../body.css"],
})
export class Deposits {
  public date = date;
  public jsonDecode = jsonDecode;
  public getStatus = getStatus;

  constructor(private otherApiService: OtherApiService) {}

  items: any = [];
  loading = true;
  typeDeposit = "";

  async ngOnInit() {
    this.loadDeposits();
  }

  async loadDeposits() {
    const data = await this.otherApiService.get("get-deposits", {
      type_deposit: this.typeDeposit,
    });
    console.log(data);
    this.loading = false;
    this.items = (data as any).result;
  }
}
