<div class="main-inner">
  <form style="width: 500px; margin: auto">
    <div class="h-container">
      <h4 style="text-align: center">Welcome.</h4>
    </div>
    <div class="h-container" *ngIf="error != ''">
      <h4 style="text-align: center; color: red">{{ error }}</h4>
    </div>
    <div class="input-container">
      <input
        type="username"
        class="input"
        [(ngModel)]="login"
        id="username"
        name="username"
        placeholder="username"
      />
    </div>
    <div class="input-container">
      <input
        type="password"
        [(ngModel)]="password"
        class="input"
        id="password"
        name="password"
        placeholder="password"
      />
    </div>
    <button class="add-button" (click)="auth()" type="button">Login</button>
  </form>
</div>
