import { NgModule } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { Routes, RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";
import { NavbarComponent } from "@header/navbar/navbar.component";
import { CardsTableComponent } from "@body/cards-table/cards-table.component";
import { PaymentsListComponent } from "@body/payments-list/payments-list.component";
import { WalletsTableComponent } from "@body/wallets-table/wallets-table.component";
import { NotificationsComponent } from "@body/notifications/notifications.component";
import { CheckerRequestsComponent } from "@body/checkerRequests/checkerRequests.component";
import { CheckerRequestComponent } from "@body/checkerRequest/checkerRequest.component";
import { Deposits } from "@body/deposits/deposits.component";
import { Keys } from "@body/keys/keys.component";
import { ClipboardModule } from "ngx-clipboard";
import { Ban } from "@body/ban/ban.component";
import { CardsArchiveTableComponent } from "@body/cards-archive-table/cards-archive-table.component";
import { Card } from "@body/card/card.component";
import { AuthGuard } from "guards";
import { Login } from "@body/login/login.component";
import { SmsListComponent } from "@body/sms-list/sms-list.component";
import { Sms } from "@body/sms/sms.component";
import { Withdraw } from "@body/withdraw/withdraw.component";
import { TraderWithdraw } from "@body/trader-withdraw/trader-withdraw.component";
import { Address } from "@body/address/address.component";
import { Transfer } from "@body/transfer/transfer.component";
import { TgBot } from "@body/tgBot/tgBot.component";

const appRoutes: Routes = [
  {
    path: "cards",
    component: CardsTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sms/:phoneNumber/:cardNumber",
    component: SmsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sms/:phone_number",
    component: Sms,
    canActivate: [AuthGuard],
  },
  {
    path: "cards-archive",
    component: CardsArchiveTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "payments",
    component: PaymentsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "wallets",
    component: WalletsTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "notifications",
    component: NotificationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "checker-requests",
    component: CheckerRequestsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "checker-request/:id",
    component: CheckerRequestComponent,
    canActivate: [AuthGuard],
  },
  { path: "deposits", component: Deposits, canActivate: [AuthGuard] },
  { path: "keys", component: Keys, canActivate: [AuthGuard] },
  { path: "ban", component: Ban, canActivate: [AuthGuard] },
  { path: "card/:id", component: Card, canActivate: [AuthGuard] },
  { path: "withdraw", component: Withdraw },
  { path: "my-withdraw", component: TraderWithdraw },
  { path: "login", component: Login },
  { path: "address", component: Address },
  { path: "transfer", component: Transfer },
  { path: "telegram", component: TgBot },
  { path: "**", redirectTo: "cards" },
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    CardsTableComponent,
    CardsArchiveTableComponent,
    Card,
    PaymentsListComponent,
    WalletsTableComponent,
    NotificationsComponent,
    CheckerRequestsComponent,
    CheckerRequestComponent,
    Deposits,
    Keys,
    Ban,
    Login,
    SmsListComponent,
    Sms,
    Withdraw,
    TraderWithdraw,
    Address,
    Transfer,
    TgBot
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    CommonModule,
    ClipboardModule,
  ],
  providers: [CookieService, AuthGuard],
  bootstrap: [AppComponent, NavbarComponent],
})
export class AppModule {}
