import { Component, Input, OnInit } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";

import { OtherApiService } from "@services/api.service";

import { banks } from "@environments/helper";

@Component({
  selector: "app-insurance-deposit",
  templateUrl: "./card.component.html",
  styleUrls: ["../body.css"],
})
export class Card implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private otherApiService: OtherApiService
  ) {}

  cardId = 0;
  loadingCard = true;
  sbp = 0;

  banks: any = banks;

  card: any = null;
  loadingEditCard = false;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.cardId = Number(params["id"]);
      this.getCard();
    });
  }

  async getCard() {
    this.loadingCard = true;
    const data = await this.otherApiService.getWichParam("get-card", {
      card_id: this.cardId,
    });
    console.log(data);
    this.loadingCard = false;
    if ((data as any).success) {
      this.card = (data as any).result;
    }
  }

  async editCard() {
    this.loadingEditCard = true;

    const data = await this.otherApiService.post("edit-card", {
      card_id: this.cardId,
      ...this.card,
    });

    this.loadingEditCard = false;

    if ((data as any).success) {
      location.href = "/cards";
    }
  }
}
