import { Component } from "@angular/core";

import { HttpClient } from "@angular/common/http";

import { OtherApiService } from "@services/api.service";

import { map, filter, scan } from "rxjs/operators";
import {
  Observable,
  Subject,
  asapScheduler,
  pipe,
  of,
  from,
  interval,
  merge,
  fromEvent,
} from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@environments/environment";
import { Router } from "@angular/router";
import { getCookie } from "@environments/helper";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent {
  private url = environment.apiUrl;

  json_token = "";
  auth_token = "";
  username = "";
  holderId = "";
  deposit = "";
  withdraw: any = false;
  forzenDeposit = "";
  countNotifs = 0;
  rate = 0;
  loadingRate = true;
  headerBnaTime = 0;
  auth = true;

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private otherApiService: OtherApiService,
    private router: Router
  ) {
    this.json_token = this.cookieService.get("json_token");
    this.username = JSON.parse(this.json_token || "{}")["username"];
    this.holderId = JSON.parse(this.json_token || "{}")["id"];
    this.auth_token = JSON.parse(this.json_token || "{}")["token"];
  }

  async ngOnInit() {
    await this.getStatusBan();
    this.auth = getCookie("json_token") ? true : false;

    this.getDeposit();
    this.getCountNotifs();
    this.loadingRate = true;
    await this.getRate();
    this.loadingRate = false;

    const period = interval(10000);
    period.subscribe((val) => {
      this.getStatusBan();
      this.getDeposit();
      this.getCountNotifs();
      this.getRate();
    });
  }

  async getCountNotifs() {
    const data = await this.otherApiService.get("get-count-new-notifs");
    if ((data as any).success) {
      this.countNotifs = (data as any).result.count_notification;
    }
  }

  async getRate() {
    const data = await this.otherApiService.getApi("get-rate");
    console.log(data);
    if ((data as any).success) {
      this.rate = Number((data as any).result.price);
    }
  }

  async getStatusBan() {
    if (this.router.url == "/ban" || this.router.url == "/") return;
    const data = await this.otherApiService.getApi("get-status-ban");
    if ((data as any).success) {
      if ((data as any).result.ban_time > 0) {
        location.href = "/ban";
      }
    }
  }

  getDeposit() {
    const url = this.url + "/trader/deposit/get?trader_id=" + this.holderId;

    this.httpClient
      .get(url)
      .toPromise()
      .then((data) => {
        this.deposit = (data as any).deposit;
        this.forzenDeposit = (data as any).forzen_deposit;
        this.withdraw = (data as any).withdraw_success;
      });
  }

  logout() {
    document.cookie = `json_token={}; expires=Fri, 01 Jan 1970 00:00:01 GMT; path=/`;
    location.href = "/login";
  }
}
