<main *ngIf="auth">
  <div class="main-inner">
    <div class="service" routerLink="/checker-requests" title="Заявки чекера">
      <img src="/assets/svg/check.svg" class="service-svg" />
    </div>

    <div class="service" routerLink="/deposits" title="История пополнений">
      <img src="/assets/svg/deposits.svg" class="service-svg" />
    </div>

    <div class="service" routerLink="/keys" title="Ключ">
      <img src="/assets/svg/key.svg" class="service-svg" />
    </div>

    <div class="service" routerLink="/cards" title="Карты">
      <img src="/assets/svg/cards-page.svg" class="service-svg" />
    </div>

    <div class="service" routerLink="/payments" title="Платежи">
      <img class="service-svg" src="/assets/svg/payments-page.svg" />
    </div>

    <div class="service" routerLink="/withdraw" title="Вывод" *ngIf="withdraw">
      <img class="service-svg" src="/assets/svg/withdraw.svg" />
    </div>

    <div class="service" routerLink="/address" title="Адрес">
      <img class="service-svg" src="/assets/svg/wallets-page.svg" />
    </div>

    <div class="service" routerLink="/notifications" title="Уведомления">
      <img src="/assets/svg/notification-new.svg" class="service-svg" />
      <span *ngIf="countNotifs > 0">{{ countNotifs }}</span>
    </div>

    <div class="delimeter"></div>

    <div class="service" routerLink="/telegram">
      <img class="service-svg" src="/assets/svg/telegram-page.svg" />
    </div>

    <a href="#" (click)="logout()">
      <div class="service">
        <img class="service-svg" src="/assets/svg/logout-page.svg" />
      </div>
    </a>

    <div class="delimeter"></div>

    <div class="service service-left">
      <img class="service-svg" src="/assets/svg/usdt-logo.svg" />
    </div>
    <div class="service service-right">
      <strong *ngIf="!loadingRate">{{ rate.toFixed(2) }}₽</strong>
      <strong *ngIf="loadingRate">Loading...</strong>
    </div>

    <div class="service service-left" routerLink="/transfer">
      <img class="service-svg" src="/assets/svg/deposit-logo.svg" />
    </div>
    <div class="service service-right" routerLink="/transfer">
      <strong>{{ deposit }}₽</strong>
    </div>
    <div class="service service-left">
      <img class="service-svg" src="/assets/svg/frozen.svg" />
    </div>
    <div class="service service-right">
      <strong>{{ forzenDeposit }}₽</strong>
    </div>
  </div>
</main>
