<div class="ban-block" style="text-align: center">
  <div *ngIf="loading">Loading...</div>
  <div *ngIf="!loading">
    <div *ngIf="ban_time > 0">
      Ваш аккаунт заблокировн до {{ date(ban_time) }}
      <div>
        <a href="https://t.me/oleg_job007" target="_blank"
          >Обратится в поддержку</a
        >
      </div>
      <div><a href="/logout">Выйти</a></div>
    </div>

    <div *ngIf="ban_time == 0">
      Ваш аккаунт разблокирован.
      <div><a href="/">На главную</a></div>
    </div>
  </div>
</div>
