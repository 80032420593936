<div class="container">
  <h2>Перевод средств другому трейдеру</h2>
  <main>
    <div class="add-deposit" style="margin-top: 0; width: 100%">
      <div style="font-size: 14px">
        Ваш ID:
        <strong style="font-size: 14px">{{ ID }}</strong>
        <img
          ngxClipboard
          [cbContent]="ID"
          src="/assets/svg/copy.svg"
          height="20"
          style="cursor: pointer"
        />
      </div>
      <div style="margin-bottom: 10px; font-size: 14px">
        Ваш Username:
        <strong style="font-size: 14px">{{ username }}</strong>
        <img
          ngxClipboard
          [cbContent]="username"
          src="/assets/svg/copy.svg"
          height="20"
          style="cursor: pointer"
        />
      </div>

      <div class="item">
        <label>Username или ID трейдера</label>
        <input
          [(ngModel)]="traderId"
          type="text"
          placeholder="Username или ID трейдера"
        />
      </div>

      <div class="item">
        <label>Сумма RUB</label>
        <input [(ngModel)]="amount" type="number" placeholder="Сумма" />
      </div>

      <div
        style="padding: 15px; text-align: center; color: red"
        *ngIf="error != ''"
      >
        {{ error }}
      </div>

      <button (click)="sendTransfer()" *ngIf="!loadingSend">Отправить</button>
      <button *ngIf="loadingSend">Loading</button>

      <button *ngIf="sendDone" style="background: green; color: #fff">
        Успешно
      </button>
    </div>

    <div *ngIf="traders.length == 0 && !loading">
      Нет ни одного трейдера с вашей процентной ставкой
    </div>
  </main>
</div>
