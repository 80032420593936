<div class="container">
  <h2>Address TRC20</h2>
  <div *ngIf="!loading">
    <h3 style="text-align: center; color: orange">{{ address }}</h3>
    <button ngxClipboard [cbContent]="address" (click)="isCopyed = true">
      <span *ngIf="!isCopyed">Копировать</span>
      <span *ngIf="isCopyed">Скопировано</span>
    </button>
  </div>
  <div style="text-align: center" *ngIf="loading">Loading...</div>
</div>
