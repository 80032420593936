import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CardsApiService, OtherApiService } from "@services/api.service";
import {
  date,
  jsonDecode,
  getStatus,
  convertDateTimeZone,
  formatNumberWithSpaces,
} from "@environments/helper";
import { interval } from "rxjs";

@Component({
  selector: "app-deposits",
  templateUrl: "./withdraw.component.html",
  styleUrls: ["../body.css"],
})
export class Withdraw {
  public date = date;
  public convertDateTimeZone = convertDateTimeZone;
  public jsonDecode = jsonDecode;
  public getStatus = getStatus;

  constructor(
    private otherApiService: OtherApiService,
    private cardsApiService: CardsApiService
  ) {}

  items: any = [];
  loading = true;
  loadingMethods = true;
  status: any = "pending";
  selectedMethodIdFilter: any = null;
  commissions: any = [];
  methods: any = {};
  page = 1;
  lastPage = 0;
  formatNumberWithSpaces = formatNumberWithSpaces;

  async ngOnInit() {
    this.getWithdraw();
    this.getComissions();

    const period = interval(60000);
    period.subscribe((val) => {
      this.loadWithdraw();
    });
  }

  async getComissions() {
    this.loadingMethods = true;
    const data = await this.otherApiService.get("get-withdraw-commissions");
    this.loadingMethods = false;
    console.log(data);
    if ((data as any).success) {
      this.commissions = (data as any).result.commissions.data;
      this.commissions.map((item: any) => {
        this.methods[item.key] = item.name;
      });
    }
  }

  async getWithdraw(pageReset?: boolean) {
    if (pageReset) this.page = 1;
    this.loading = true;
    await this.loadWithdraw();
    this.loading = false;
  }

  async loadWithdraw() {
    const data = await this.cardsApiService.getWithdraw(false, {
      page: this.page,
      status: this.status,
      method: this.selectedMethodIdFilter
        ? this.commissions[this.selectedMethodIdFilter]?.key
        : null,
    });
    if ((data as any).success) {
      this.items = (data as any).data;
      this.lastPage = Number((data as any).last_page);
    }
  }

  async setPage(page: number) {
    this.page = page;
    await this.getWithdraw();
  }

  getVisiblePages() {
    const visiblePages = [];

    const startPage = Math.max(1, this.page - 2);
    const endPage = Math.min(this.lastPage, this.page + 2);

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  }

  async withdrawTake(i: any, id: any) {
    this.items[i]["loading"] = true;
    const data = await this.cardsApiService.withdrawTake(id);
    this.items[i]["loading"] = false;

    if ((data as any).message == "taken") {
      this.items[i]["trader_id"] = true;
    }

    if ((data as any).success) {
      this.items[i]["success"] = true;
    }
  }
}
