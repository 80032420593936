<div class="container">
  <h2>Telegram</h2>

  <div *ngIf="!loading">
    <a [href]="link" target="_blank"
      ><button *ngIf="data.chat_id == null">Подключить</button>
    </a>

    <div *ngIf="Number(data.chat_id) > 0">
      <div style="margin-bottom: 10px; font-size: 14px">
        Chat ID:
        <strong style="font-size: 14px">{{ data.chat_id }}</strong>
        <img
          ngxClipboard
          [cbContent]="data.chat_id"
          src="/assets/svg/copy.svg"
          height="20"
          style="cursor: pointer"
        />
      </div>

      <div style="margin-bottom: 10px; font-size: 14px">
        TG username:
        <strong style="font-size: 14px">{{ data.tg_username }}</strong>
        <img
          ngxClipboard
          [cbContent]="data.tg_username"
          src="/assets/svg/copy.svg"
          height="20"
          style="cursor: pointer"
        />
      </div>

      <button (click)="remove()" style="background: orange; color: #000">
        Отключить
      </button>
    </div>
  </div>
  <div style="text-align: center" *ngIf="loading">Loading...</div>
</div>
