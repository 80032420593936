<div class="container">
  <h2>SMS по реквезиту</h2>
  <main>
    <div style="height: 700px" id="main-table"></div>
    <div style="display: none" id="modal-set-payment-id" class="modal-wrapper">
      <div class="modal-overlay" (click)="onCloseModal()" #overlay></div>
      <section class="modal" #modal>
        <div class="modal-inner">
          <span style="margin-bottom: 0.5em">Введите айди платежа.</span>
          <form (ngSubmit)="onSaveModal()" [formGroup]="smsSetPaymentIdForm">
            <div class="input-container">
              <input
                required
                formControlName="paymentId"
                placeholder="Айди платежа"
              />
            </div>
            <button>Сохранить</button>
          </form>
        </div>
      </section>
    </div>
  </main>
</div>
