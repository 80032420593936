import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { interval } from "rxjs";
import { OtherApiService } from "@services/api.service";
import { date, jsonDecode, getStatus, leftTime } from "@environments/helper";

@Component({
  selector: "app-checker-requests",
  templateUrl: "./checkerRequests.component.html",
  styleUrls: ["../body.css"],
})
export class CheckerRequestsComponent {
  public date = date;
  public leftTime = leftTime;
  public jsonDecode = jsonDecode;
  public getStatus = getStatus;

  constructor(private otherApiService: OtherApiService) {}

  items: any = [];
  loading = true;

  async ngOnInit() {
    const data = await this.otherApiService.get("get-checker-requests");
    this.loading = false;
    console.log(data);
    this.items = (data as any).result;

    if (this.items.length > 0) {
      const period = interval(1000);

      period.subscribe((val) => {
        this.items[0]["closing_time_text"] = "";
      });
    }
  }
}
