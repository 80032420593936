import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { OtherApiService } from "@services/api.service";
import { date, jsonDecode, getStatus, getCookie } from "@environments/helper";

@Component({
  selector: "app-deposits",
  templateUrl: "./transfer.component.html",
  styleUrls: ["../body.css"],
})
export class Transfer {
  public date = date;
  public jsonDecode = jsonDecode;
  public getStatus = getStatus;

  constructor(private otherApiService: OtherApiService) {}

  getCookie: any = getCookie;
  JSON = JSON;
  items: any = [];
  loading = true;
  typeDeposit = "";
  loadingTraders: any = true;
  loadingSend: any = false;
  sendDone: any = false;
  selectedTraderId: any = 0;
  traders: any = [];
  amount: any = "";
  traderId: any = "";
  error: any = "";
  ID: any = JSON.parse(getCookie("json_token") || "{}")["id"];
  username: any = JSON.parse(getCookie("json_token") || "{}")["username"];

  async sendTransfer() {
    if (Number(this.amount) <= 0) {
      return;
    }
    this.error = "";
    this.loadingSend = true;
    const data = await this.otherApiService.post("send-balance-to-trader", {
      amount: this.amount,
      trader_id: this.traderId,
    });
    this.loadingSend = false;
    console.log(data);
    if ((data as any).success) {
      this.sendDone = true;
      this.amount = "";
      this.traderId = "";
    } else {
      this.error = (data as any).result;
    }
  }
}
