import { Component } from "@angular/core";
import { ActivatedRoute } from '@angular/router'

import { TabulatorFull as Tabulator } from "tabulator-tables";
import { FormGroup, FormControl } from "@angular/forms";

import { CookieService } from "ngx-cookie-service";
import { environment } from "@environments/environment";
import { SmsApiService } from "@services/api.service";

import { getCookie } from "@environments/helper";

@Component({
  selector: 'app-sms-list',
  templateUrl: './sms-list.component.html',
  styleUrls: ['./sms-list.component.css']
})
export class SmsListComponent {

  jsonToken = "";
  username = "";
  holderId = "";
  cardNumber = "";
  phoneNumber = "";
  
  smsId = "";
  currentRow = "";

  smsSetPaymentIdForm: any = new FormGroup({
    paymentId: new FormControl()
  });


  table: any;

  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private smsApiService: SmsApiService
  ) {
    this.jsonToken = this.cookieService.get("json_token");
    this.username = JSON.parse(this.jsonToken || "{}")["username"];
    this.holderId = JSON.parse(this.jsonToken || "{}")["id"];
  }

  ngOnInit() {
    this.cardNumber = this.route.snapshot.paramMap.get('cardNumber')!; 
    this.phoneNumber = this.route.snapshot.paramMap.get('phoneNumber')!; 
    this.drawTable();
  }

  public onCloseModal(): void {
    document.getElementById("modal-set-payment-id")!.style.display="none"
  }

  public async onSaveModal(): Promise<void> {
    const formValue = this.smsSetPaymentIdForm.value

    const smsId = this.smsId
    const paymentId = formValue['paymentId']

    const success = await this.smsApiService.setPaymentId(smsId, paymentId)

    if (success) {
      this.table.updateData([{id:smsId, payment_id:paymentId}])

      this.smsSetPaymentIdForm.reset()
      this.onCloseModal()
    }
  }

  rowContextMenu = (e: any, component: any) => {
    var optionSetPaymentId = {
      label: "Изменить айди платежа",
      action: async (e: any, row: any) => {
        this.smsId = row.getCell("id").getValue()

        document.getElementById("modal-set-payment-id")!.style.display="block"
      }
    };

    var menu = [];

    const paymentId = component.getCell("payment_id").getValue();

    if (paymentId == null) menu.push(optionSetPaymentId);

    

    return menu;
  }

  private drawTable(): void {
    var div = document.createElement("div");

    this.table = new Tabulator(div, {
      persistence: {
        filter: true,
        sort: true
      },
      persistenceID: "cards",
      index: "id",
      filterMode: "remote",
      sortMode: "remote",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 300,
      paginationSizeSelector: [5, 10, 15, 20, 50, 100, true],
      rowContextMenu: this.rowContextMenu,
      ajaxURL:
        environment.apiUrl +
        "/sms/get?phone_number=" + this.phoneNumber +
        "&card_number=" + this.cardNumber,
      ajaxConfig: {
        headers: {
          Authorization: JSON.parse(getCookie("json_token") || "{}")["token"],
        },
      },
      columns: [
        {
          title: "ID",
          field: "id",
          headerFilter: "number",
          hozAlign: "right"
        },
        {
          title: "Платёж",
          field: "payment_id",
          headerFilter: "input",
          headerFilterFunc:"=",
          formatter: function (cell) {
            if(cell.getValue() == null){
              return "<a>Не найден</a>"
            }
            return cell.getValue();
          },
        },
        {
          title: "Номер карты",
          field: "card_number",
          headerFilter: "input",
        },
        {
          title: "Сумма",
          field: "amount",
          headerFilter: "input",
        },
        {
          title: "Дата сохранения",
          field: "created_at",
          headerFilter: "input",
          formatter: "datetime",
          formatterParams: {
            inputFormat: "yyyy-MM-dd HH:mm:ss.uZ",
            outputFormat: "yyyy-MM-dd HH:mm:ss",
            invalidPlaceholder: "(invalid date)",
          }
        },
        {
          title: "Текст",
          field: "text",
          headerFilter: "input",
          formatter: function (cell) {
            return cell.getValue().replace(/(.{40})/g,"$1<br>");
          },
          hozAlign: "right"
        }
      ],
      layout: "fitData",
      height: "100%",
    });
    document.getElementById("main-table")?.appendChild(div);
    
    this.table.on("cellClick", (e: any, cell: any) =>{

      console.log("value's " + cell.getValue() + "\nAnd field's " + cell.getField());

      if(cell.getValue() == null && cell.getField() == 'payment_id'){
        this.smsId = cell.getRow().getCell("id").getValue()
        
        document.getElementById("modal-set-payment-id")!.style.display="block"
      }
    });
  }

  dummy(){console.log("Dummy!")}
}
