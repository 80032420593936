import { Component } from "@angular/core";
import { OtherApiService } from "@services/api.service";
import { date, jsonDecode, getStatus } from "@environments/helper";

@Component({
  selector: "app-deposits",
  templateUrl: "./address.component.html",
  styleUrls: ["../body.css"],
})
export class Address {
  public date = date;
  public jsonDecode = jsonDecode;
  public getStatus = getStatus;

  constructor(private otherApiService: OtherApiService) {}

  loading = true;
  address = "";
  isCopyed = false;

  async ngOnInit() {
    this.load();
  }

  async load() {
    const data = await this.otherApiService.get("get-address");
    this.loading = false;
    this.address = (data as any).result.address;
  }
}
