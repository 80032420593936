import { Component } from '@angular/core';
import { FormControl } from '@angular/forms'
import { OtherApiService } from '@services/api.service'
import { date } from '@environments/helper'




@Component({
  selector: 'app-notifs',
  templateUrl: './notifications.component.html',
  styleUrls: ['../body.css']
})


export class NotificationsComponent {

  public date = date

  constructor(
      private otherApiService: OtherApiService,
  ) {}

  items = [];
  loadingNotifs = true

  async ngOnInit() {
    const data = await this.otherApiService.get('get-notifs')
    console.log(data);
    this.loadingNotifs = false
    if((data as any).success){
      this.items = (data as any).result
    }
  }
  
}
