import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "@environments/environment";
import { firstValueFrom } from "rxjs";
import { CookieService } from "ngx-cookie-service";

import { getCookie } from "@environments/helper";

const headers = {
  Authorization: JSON.parse(getCookie("json_token") || "{}")["token"],
};

export class ApiSevice {
  isSuccess(data: any): boolean {
    const success = data.success;

    if (!success) {
      switch (data.message.substr(0, 4)) {
        case "1301":
          alert("СБП уже активирован для этого ФИО.");
          break;
        default:
          console.log(data.message.substr(0, 4));
          alert("Что-то не так.");
      }
    }

    return success;
  }
}

@Injectable({
  providedIn: "root",
})
export class PaymentsApiService extends ApiSevice {
  private url: string = environment.apiUrl;

  private jsonToken: string;
  private traderId: string;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    super();

    this.jsonToken = this.cookieService.get("json_token");
    this.traderId = JSON.parse(this.jsonToken || "{}")["id"];
  }

  async confirm(id: string, real_amount: string | number) {
    var url = this.url + "/payments/confirm?paymentid=" + id;
    if (real_amount) {
      url = url + "&real_amount=" + real_amount;
    }

    const req = this.http.get(url, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async cancel(id: string) {
    const url = this.url + "/payments/cancel";
    const body = new FormData();
    body.append("paymentid", id);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }
  async resendCallback(id: string) {
    var url = this.url + "/payments/resendCallback?paymentid=" + id;

    const req = this.http.get(url, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }
}

@Injectable({
  providedIn: "root",
})
export class CardsApiService extends ApiSevice {
  private url: string = environment.apiUrl;

  private jsonToken: string;
  private traderId: string;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    super();

    this.jsonToken = this.cookieService.get("json_token");
    this.traderId = JSON.parse(this.jsonToken || "{}")["id"];
  }

  async activateAll() {
    const url = this.url + "/trader/cards/activate";
    const body = new FormData();
    body.append("trader_id", this.traderId);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async deactivateAll() {
    const url = this.url + "/trader/cards/deactivate";
    const body = new FormData();
    body.append("trader_id", this.traderId);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async addArchive(id: any) {
    const url = this.url + "/api/trader?method=add-archive-card&card_id=" + id;
    const body = new FormData();

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }
  async removeArchive(id: any) {
    const url =
      this.url + "/api/trader?method=remove-archive-card&card_id=" + id;
    const body = new FormData();

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async telegramGet() {
    const url =
      this.url + "/trader/telegram/get?trader_id=" + this.traderId;
    const body = new FormData();

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    return data;
  }

  async telegramDelete() {
    const url =
      this.url + "/trader/telegram/delete?trader_id=" + this.traderId;
    const body = new FormData();

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    return data;
  }

  async activate(number: string) {
    const url = this.url + "/cards/activate";
    const body = new FormData();
    body.append("number", number);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async deactivate(number: string) {
    const url = this.url + "/cards/deactivate";
    const body = new FormData();
    body.append("number", number);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async activateSbp(number: string) {
    const url = this.url + "/cards/sbp/activate";
    const body = new FormData();
    body.append("number", number);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async deactivateSbp(number: string) {
    const url = this.url + "/cards/sbp/deactivate";
    const body = new FormData();
    body.append("number", number);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async create(card: any) {
    const url = this.url + "/cards/create";
    const body = new FormData();

    for (let key in card) {
      body.append(key, card[key]);
    }

    body.append("trader_id", this.traderId);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async getWithdraw(trader: boolean = false, params: any = {}) {
    console.log(params);
    let urlParams = Object.keys(params)
      .map((key) => {
        if (!key || !params[key]) return "";
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      ?.join("&");

    urlParams += trader ? `&trader_id=${this.traderId}` : `&trader_id=0`;

    const url = this.url + "/withdrawals/get?" + urlParams;
    const body = new FormData();
    const req = this.http.post(url, body, { responseType: "json", headers });
    return await req.toPromise();
  }

  async withdrawTake(id: any = 0) {
    const url = this.url + "/withdrawals/take";
    const body = new FormData();
    body.append("trader_id", this.traderId);
    body.append("id", id);
    const req = this.http.post(url, body, { responseType: "json", headers });
    return await req.toPromise();
  }
}

@Injectable({
  providedIn: "root",
})
export class SmsApiService extends ApiSevice {
  private url: string = environment.apiUrl;

  constructor(private http: HttpClient) {
    super();
  }

  async setPaymentId(smsId: any, paymentId: any) {
    const url = this.url + "/sms/setPaymentId";
    const body = new FormData();

    body.append("paymentId", paymentId);
    body.append("smsId", smsId);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }
}

@Injectable({
  providedIn: "root",
})
export class OtherApiService extends ApiSevice {
  constructor(private http: HttpClient, private cookieService: CookieService) {
    super();
  }

  private url: string = environment.apiUrl;

  private headers = {
    "Content-Type": "application/json",
    Authorization: getCookie("json_token")
      ? JSON.parse(getCookie("json_token") || "{}")["token"]
      : "",
  };

  async get(method: string, _params = {}) {
    const params = { method, ..._params };
    return await firstValueFrom(
      this.http.get(this.url + "/api/trader", { params, headers: this.headers })
    );
  }

  async getWichParam(method: string, _params = {}) {
    const params = { method, ..._params };
    return await firstValueFrom(
      this.http.get(this.url + "/api/trader", {
        params: params,
        headers: this.headers,
      })
    );
  }

  async post(method: string, _params = {}) {
    const params = { method, ..._params };
    return await firstValueFrom(
      this.http.post(this.url + "/api/trader", null, {
        params: params,
        headers: this.headers,
      })
    );
  }

  async getApi(method: string, _params = {}) {
    const params = { method, ..._params };
    return await firstValueFrom(
      this.http.get(this.url + "/api/", {
        params: params,
        headers: this.headers,
      })
    );
  }

  async withdrawAction(params: any, method: any) {
    const body = new FormData();

    body.append("comment", params.comment);
    body.append("id", params.id);

    for (var i = 0; i < params.files.length; i++) {
      let file = params.files[i];
      body.append(`files[${i}]`, file);
    }

    const req = this.http.post(
      this.url + "/api/trader?method=" + method,
      body,
      { responseType: "json", headers }
    );

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async checkerAction(params: any) {
    const body = new FormData();

    body.append("status", params.status);
    body.append("message", params.comment);
    body.append("new_amount", params.new_amount);
    body.append("id", params.id);

    for (var i = 0; i < params.files.length; i++) {
      let file = params.files[i];
      body.append(`files[${i}]`, file);
    }

    const req = this.http.post(
      this.url + "/api/trader?method=set-checker-request",
      body,
      { responseType: "json", headers }
    );

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async transferBalance(params: any, method: any) {
    const body = new FormData();
    console.log(params);

    body.append("trader_id", params.trader_id);
    body.append("amount", params.amount);

    const req = this.http.post(
      this.url + "/api/trader?method=" + method,
      body,
      { responseType: "json", headers }
    );

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }
}
